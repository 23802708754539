import React, { useState } from "react";
import { motion } from "framer-motion";
import Intro from "../components/Introduction/Intro";
import Course from "../components/CourseBundles/Course";
import Feedback from "../components/Feedback/Feedback";
import Testimonial from "../components/Testimonial/Testimonial";
import About from "../components/AbooutUs/About";
import WhySection from "../components/WhySection/WhySection";
import ContactSection from "../components/contactUs/ContactUs";
import AssistUs from "../components/AssistUs/AssistUs";

const Homepage = () => {
  const [toggle, setToggle] = useState(false);

  // Animation variants for the text
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="min-[320px]:px-8 sm:px-8 md:px-12 lg:px-16 my-4"> {/* Responsive padding */}
      <Intro />
      <AssistUs />
      <Course />
      <About />
      <section className="row py-4 sm:py-6 lg:py-8" id="feedback"> {/* Responsive padding for the section */}
        <div className="col-12">
          <motion.div
            className="text-center font-extrabold text-3xl sm:text-4xl md:text-5xl" // Responsive text size
            initial="hidden"
            whileInView="visible"
            variants={textVariants}
            viewport={{ once: true, amount: 0.2 }} // Trigger when 20% of the text is in view
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            {!toggle ? "Feedbacks" : "Testimonials"}
          </motion.div>
        </div>
        <div className="col-12"> <Feedback /> </div>
      </section>

      <Testimonial />
      <WhySection />
      <ContactSection />
    </div>
  );
};

export default Homepage;
