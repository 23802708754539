import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./pages/Homepage";
import DisclaimerModal from "./components/DisclamerDialogue/Disclamer";
import Footer from "./components/Footer/Footer";


const App = () => {
  return (
    <div>
      <Navbar />
      <DisclaimerModal />
      <Homepage />
      <Footer />
    </div>
  );
};

export default App;
