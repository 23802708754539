import React, { useState } from "react";
import { motion } from "framer-motion";
import "./intro.css";
import StudentCountAnimation from "../StudentCount/StudentCountAnimation";
import AppDownload from "../AppDownload";
import { analytics } from "../../utils/firebase";
import { logEvent } from "firebase/analytics";

const Intro = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const manish = "../../assets/manish_2.png";
  const playStore = "../../assets/googlePlay.png";
  const appleStore = "../../assets/appleStore.png";
  // Animation Variants
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.1,
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#cbd9fe",
    },
    tap: { scale: 0.95 },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const openModal = (e) => {
    e.preventDefault()
    setModalIsOpen(true)
    logEvent(analytics, 'ios_download', {
      button_name: 'ios_download'
    });
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }


  return (
    <motion.div
      className="page-content"
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <div className="content1_background row ">
        {/* <div className=" w-full h-full content2_background"></div> */}
        <motion.div className="col col-12 min[640px]:pt-4 min-[320px]:pt-10">
          <motion.div variants={textVariants}>
            <span className="heading min-[320px]:text-xl md:text-5xl">
              Elevate Your Financial Game
            </span>
          </motion.div>
        </motion.div>
        <motion.div className="col col-12 col-lg-8 lg:pb-12 px-4">
          <motion.div
            className="main_heading md:mt-[1rem] md:text-2xl]"
            variants={textVariants}
            transition={{ delay: 0.2 }}
          >
            Master the Money Mindset
          </motion.div>
          <motion.div
            className="sub_heading md:text-[1.5rem]"
            variants={textVariants}
            transition={{ delay: 0.4 }}
          >
            Break barriers and embrace abundance with Money Mindset Mastery,
            your path to lasting financial success.
          </motion.div>
          <div className="flex space-x-4 py-4 items-center flex-wrap">
            <motion.a
              className=""
              variants={textVariants}
              transition={{ delay: 0.6 }}
              href="https://gmhmw.courses.store/courses"
              target="_blank"
            >
              <motion.span
                className="call_to_action hover:cursor-pointer transition-all duration-200"
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                Let's go
              </motion.span>
            </motion.a>

            <motion.a
              href="https://apps.apple.com/in/app/the-chartistt/id1637055376"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
              className="text-white p-2 hover:cursor-pointer rounded-lg"
              onClick={openModal}
            >
              <img
                alt="apple store"
                size={24}
                src={appleStore}
                className="w-8 h-8"
              />
            </motion.a>
            <motion.a
              href="https://play.google.com/store/apps/details?id=co.robin.gmhmw&pcampaignid=web_share"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
              onClick={() => {
                logEvent(analytics, 'android_download', {
                  button_name: 'android_download'
                });
              }}
            >
              <img
                alt="google play"
                size={24}
                src={playStore}
                className="w-8 h-8"
              />
            </motion.a>

            <StudentCountAnimation />
          </div>
        </motion.div>

        <motion.div className="col col-12 col-lg-4 flex flex-col justify-end items-center ">
          <motion.img
            src={manish}
            alt="Manish"
            className="manish_image min-[320px]:max-w-[15rem] h-auto md:w-[20rem]"
            variants={imageVariants}
          />
        </motion.div>
      </div>
      <AppDownload modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </motion.div>
  );
};

export default Intro;
