import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase";

const DisclaimerModal = () => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => {
    logEvent(analytics, 'closed_disclaimer', {
      button_name: 'closed_disclaimer'
    });
    setShowModal(false);
  };

  const handleAutoClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleAutoClose(); // Automatically close the modal after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <>
      {showModal && (
        <>
          <div className="modal-backdrop fade show" onClick={handleClose}></div>
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-lg shadow-lg border-0">
                <div className="modal-body p-6 ">
                  <div className="text-center my-2">
                    <h5 className="modal-title text-xl font-semibold">
                      Disclaimer{" "}
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2 w-5 h-5"
                      />
                    </h5>
                  </div>
                  <p className="text-gray-700">
                    We are not SEBI-registered advisors. All information shared
                    here is solely for educational purposes and should not be
                    considered as financial advice. Please conduct your own
                    research and consult with a licensed financial advisor
                    before making any investment decisions. We do not take
                    responsibility for any financial losses you may incur as a
                    result of following the information provided.
                  </p>
                  <div className="flex justify-center my-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DisclaimerModal;
