// src/components/WhySection.js

import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const WhySection = () => {
  const sectionRef = useRef(null);

  const cardData = [
    {
      title: "Ease of Explanation",
      description:
        "We simplify complex stock market topics, making them easy to understand for everyone.",
      icon: "📈",
    },
    {
      title: "Engaging Learning",
      description:
        "Interactive sessions between students and tutors create an engaging learning environment.",
      icon: "🧑‍🏫",
    },
    {
      title: "Comprehensive Curriculum",
      description:
        "Our curriculum covers all aspects of the stock market, from basics to advanced strategies.",
      icon: "📚",
    },
    {
      title: "Affordable with EMI Options",
      description:
        "We offer affordability with easy payment options, including EMIs for all learners.",
      icon: "💸",
    },
    {
      title: "Pro Mentorship",
      description:
        "Learn from industry experts who provide personalized mentorship and guidance.",
      icon: "👨‍🏫",
    },
  ];

  return (
    <motion.section
      ref={sectionRef}
      className="relative bg-gradient-to-tr from-blue-300 to-blue-100 text-gray-800 px-4 sm:px-5 py-6 sm:py-8 rounded-3xl overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      id="whyToChoose"
    >
      <Background />
      <div className="container mx-auto text-center mb-6 sm:mb-10">
        <h2 className="text-3xl sm:text-4xl font-bold mb-4">Why Choose Us?</h2>
        <p className="text-base sm:text-lg">
          Discover the unique qualities that set us apart.
        </p>
      </div>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
        {cardData.map((card, index) => (
          <Card key={index} card={card} index={index} />
        ))}
      </div>
    </motion.section>
  );
};

// Background component with candles, circles, and cubes
const Background = () => {
  const shapes = [
    {
      type: "circle",
      color: "#FF6B6B",
      size: 100,
      x: "0%",
      y: "20%",
      delay: 0,
    },
    {
      type: "cube",
      color: "#FFD93D",
      size: 50,
      x: "30%",
      y: "40%",
      delay: 0.5,
    },
    {
      type: "candle",
      color: "green",
      size: 60,
      x: "80%",
      y: "50%",
      delay: 1,
    },
    {
      type: "circle",
      color: "#4D96FF",
      size: 80,
      x: "50%",
      y: "70%",
      delay: 1.5,
    },
    {
      type: "candle",
      color: "red",
      size: 30,
      x: "70%",
      y: "60%",
      delay: 2,
    },
    {
      type: "circle",
      color: "#4D96FF",
      size: 80,
      x: "80%",
      y: "70%",
      delay: 1.5,
    },
  ];

  return (
    <div className="absolute inset-0 z-0 overflow-hidden">
      {shapes.map((shape, index) => (
        <motion.div
          key={index}
          className={`absolute ${shape.type === "circle" ? "rounded-full" : ""
            }`}
          style={{
            width: shape.size,
            height: shape.type === "candle" ? shape.size * 2 : shape.size,
            backgroundColor: shape.color,
            top: shape.y,
            left: shape.x,
            transform: shape.type === "cube" ? "rotate(45deg)" : "none",
          }}
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.8, 1, 0.8],
            y: [shape.y, `${parseFloat(shape.y) + 20}%`, shape.y],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: "mirror",
            ease: "easeInOut",
            delay: shape.delay,
          }}
        />
      ))}
    </div>
  );
};

const Card = ({ card, index }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 300,
        damping: 20,
        delay: index * 0.1,
      },
    },
  };

  return (
    <motion.div
      className="relative w-full h-64 sm:h-72 lg:h-80 bg-white shadow-lg rounded-lg overflow-hidden"
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      variants={cardVariants}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <motion.div
        className="absolute inset-0"
        initial={{ clipPath: "circle(0% at 50% 50%)" }}
        whileHover={{ clipPath: "circle(150% at 50% 50%)" }}
        transition={{ duration: 0.5 }}
        style={{
          background:
            "radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 70%)",
        }}
      />
      <div className="absolute inset-0 p-3 sm:p-4 md:p-5 flex flex-col items-center justify-center"> {/* Adjusted padding for better mobile support */}
        <div className="flex gap-x-2 items-center">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2">{card.title}</h3> {/* Smaller on mobile */}
          <p className="text-2xl sm:text-3xl md:text-4xl">{card.icon}</p> {/* Smaller icon size on mobile */}
        </div>
        <p className="text-sm sm:text-base md:text-lg text-center">{card.description}</p> {/* Responsive description size */}
      </div>
    </motion.div>
  );
};

export default WhySection;
