import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";

const StudentCountAnimation = () => {
  const [totalStudents, setTotalStudents] = useState(10000);
  const count = useMotionValue(0);
  const roundedCount = useTransform(count, Math.round);
  const [displayCount, setDisplayCount] = useState(0); // State to hold the display value

  // Function to add a random number of students
  const addRandomStudents = () => {
    const randomStudents = Math.floor(Math.random() * 5) + 1; // Random value between 1 and 5
    setTotalStudents((prev) => prev + randomStudents);
  };

  useEffect(() => {
    // Animate the count when totalStudents changes
    const animation = animate(count, totalStudents, { duration: 1 });
    return animation.stop; // Clean up the animation on unmount
  }, [totalStudents]);

  useEffect(() => {
    // Update displayCount whenever roundedCount changes
    const unsubscribe = roundedCount.onChange((latest) => {
      setDisplayCount(latest); // Update the display count
    });

    return () => {
      unsubscribe(); // Clean up the subscription on unmount
    };
  }, [roundedCount]);

  useEffect(() => {
    // Set an interval to add students every 5 to 10 minutes
    const interval = Math.floor(Math.random() * (10 - 5 + 1) + 5) * 60 * 1000; // Random time between 5 and 10 minutes
    const timer = setInterval(addRandomStudents, interval);

    return () => clearInterval(timer); // Clean up the timer on unmount
  }, []);

  return (
    <motion.h1 className="text-white text-lg">
      {displayCount}
      <span className="text-green-500 text-lg font-bold">+ students</span>
    </motion.h1>
  );
};

export default StudentCountAnimation;
