import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'; // Ensure you have react-leaflet installed
import 'leaflet/dist/leaflet.css';

const ContactSection = () => {
    const position = [22.7280553, 75.8841392]; // Replace with your office location coordinates
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault()
        setEmail('');
        setMessage('');
    }

    return (
        <div className="flex flex-col md:flex-row justify-between py-8" id='contact'>
            <div className="w-full md:w-1/2">
                <MapContainer center={position} zoom={13} className="h-96 z-0">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={position} />
                </MapContainer>
            </div>
            <div className="w-full md:w-1/2 p-4">
                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            required
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500 p-2"
                            placeholder="Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            required
                            rows="4"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500 p-2"
                            placeholder="Your message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white font-bold py-2 rounded hover:bg-blue-600 transition duration-200"
                    >
                        Send Message
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactSection;