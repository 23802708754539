// src/components/Footer.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import AppDownload from "../AppDownload";
import { analytics } from "../../utils/firebase";
import { logEvent } from "firebase/analytics";



const Footer = () => {
  const facebookLogo = "/assets/facebook.png";
  const instagramLogo = "/assets/intagram.png";
  const twitterxLogo = "/assets/twitterX.png";
  const linkedinLogo = "/assets/linkedIn.png";
  const youtubeLogo = "/assets/youtube.png";
  const googlePlay = "/assets/googlePlay.png";
  const appleStore = "/assets/appleStore.png";
  const telegram = "/assets/telegram.png";

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = (e) => {
    e.preventDefault()
    setModalIsOpen(true)
    logEvent(analytics, 'ios_download', {
      button_name: 'ios_download'
    });
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the context menu from appearing
  };

  return (
    <footer className="bg-gradient-to-tr from-blue-900 via-blue-800 to-blue-700 text-white p-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        {/* Logo and Company Info */}
        <div className="flex flex-col items-center md:items-start">
          <img
            alt="logo"
            src={("/assets/company_logo.png")}
            className="w-12 h-12 "
            onContextMenu={handleContextMenu}
            style={{ cursor: 'not-allowed' }}
          />
          <h3 className="text-xl font-semibold">The Chartistt</h3>
          <p className="text-sm mt-2">Empowering your stock market journey.</p>
        </div>

        {/* Download Buttons */}
        <div className="flex flex-col items-center">
          <motion.a
            href="https://play.google.com/store/apps/details?id=co.robin.gmhmw&pcampaignid=web_share"
            className="flex items-center bg-blue-600 hover:bg-blue-700 p-3 rounded-full mb-3"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            target="_blank"
            onClick={() => {
              logEvent(analytics, 'android_download', {
                button_name: 'android_download'
              });
            }}
          >
            <img
              alt="google play"
              size={24}
              className="mr-2 w-8 h-8"
              src={googlePlay}
            />
            <span>Download on Google Play</span>
          </motion.a>
          <motion.a
            href="/"
            className="flex items-center bg-blue-600 hover:bg-blue-700 p-3 rounded-full"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            // target="_blank"
            onClick={openModal}
          >
            <img
              alt="apple store"
              size={24}
              className="mr-2 w-8 h-8"
              src={appleStore}
            />
            <span>Download on the App Store</span>
          </motion.a>
        </div>

        {/* Contact and Social Links */}
        <div className="flex flex-col items-center md:items-start">
          <motion.button
            className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-full text-sm mb-4"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            Contact Us
          </motion.button>

          <div className="flex space-x-4">
            <motion.a
              href="https://www.facebook.com/thechartistt/"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="facebook" src={facebookLogo} className="w-[30px] h-[30px]" />
            </motion.a>
            <motion.a
              href="https://x.com/The_Chartistt"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="twitter" size={10} src={twitterxLogo} className="w-[30px] h-[30px]" />
            </motion.a>
            <motion.a
              href="https://www.instagram.com/the_chartistt?igsh=bnp3Z3I1ajlobWxm"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="instagram" size={10} src={instagramLogo} className="w-[30px] h-[30px]" />
            </motion.a>
            <motion.a
              href="https://www.linkedin.com/company/the-chartistt/"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="linkedIn" size={10} src={linkedinLogo} className="w-[30px] h-[30px]" />
            </motion.a>
            <motion.a
              href="https://youtube.com/@thechartistt?si=n4EoHDcLEDFkZ_LH"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="youtube" size={10} src={youtubeLogo} className="w-[30px] h-[30px]" />
            </motion.a>
            <motion.a
              href="https://t.me/thechartistt"
              target="_blank"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <img alt="youtube" size={10} src={telegram} className="w-[30px] h-[30px]" />
            </motion.a>
          </div>
        </div>
      </div>

      {/* Copyright Info */}
      <div className="mt-8 text-center text-sm border-t border-blue-600 pt-4">
        &copy; {new Date().getFullYear()} The Chartistt. All rights reserved.
      </div>

      <AppDownload modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </footer>
  );
};

export default Footer;
