import React from 'react';
import { analytics } from '../../utils/firebase';
import { logEvent } from 'firebase/analytics';

const AssistUs = () => {
    const whatsappNumber = '+91 6398290107'; // Replace with your actual number
    const whatsappLink = `https://wa.me/${whatsappNumber.replace(/\s+/g, '')}`; // Format for WhatsApp link

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Need Assistance?</h2>
            <p style={styles.description}>
                If you have any questions or need help choosing a course, feel free to reach out!
            </p>
            <div style={styles.contactInfo}>
                <span style={styles.whatsappIcon}>📞</span>
                <span style={styles.whatsappNumber}>{whatsappNumber}</span>
            </div>
            <a href={whatsappLink} style={styles.button} onClick={() => {
                logEvent(analytics, 'whatsapp_contact', {
                    button_name: 'whatsapp_contact'
                });
            }}>
                Chat with Us on WhatsApp
            </a>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        textAlign: 'center',
        marginTop: '20px',
    },
    header: {
        fontSize: '24px',
        color: '#333',
    },
    description: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '15px',
    },
    contactInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        marginBottom: '15px',
    },
    whatsappIcon: {
        marginRight: '10px',
    },
    whatsappNumber: {
        fontWeight: 'bold',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        backgroundColor: '#25D366', // WhatsApp green
        color: '#fff',
        borderRadius: '5px',
        textDecoration: 'none',
        fontSize: '16px',
    },
};

export default AssistUs;