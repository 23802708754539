import React from "react";
import { motion } from "framer-motion";
import './About.css'

const About = () => {
  return (
    <section
      className="relative back_about text-white lg:px-5 md:px-3 sm:px-1 py-8 overflow-hidden rounded-3xl"
      id="about"
    >
      {/* Background 3D Shapes */}
      <div className="absolute inset-0">
        {/* 3D Cube (symbolic of building blocks for education) */}
        <svg className="absolute top-[3rem] left-[15rem] md:top-[5rem] md:left-[20rem]" width="100" height="100">
          <motion.rect
            x="20"
            y="20"
            width="60"
            height="60"
            fill="#ffffff"
            transform="rotateX(20deg) rotateY(20deg)"
            style={{ transformOrigin: "center" }}
            initial={{ opacity: 0, scale: 0.5, rotate: 45 }}
            animate={{ opacity: 0.6, scale: 1, rotate: 0 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: "mirror" }}
          />
          <motion.line
            x1="20"
            y1="20"
            x2="80"
            y2="20"
            stroke="#ffffff"
            strokeWidth="2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: "mirror" }}
          />
          <motion.line
            x1="20"
            y1="80"
            x2="80"
            y2="80"
            stroke="#ffffff"
            strokeWidth="2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: "mirror" }}
          />
        </svg>

        {/* 3D Bars (representing stock market growth) */}
        <svg className="absolute bottom-0 right-10 md:right-20" width="120" height="200">
          <motion.rect
            x="10"
            y="100"
            width="20"
            height="100"
            fill="#ffffff"
            transform="skewY(-10deg)"
            style={{ transformOrigin: "bottom" }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 0.3, height: 100 }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
          <motion.rect
            x="40"
            y="60"
            width="20"
            height="140"
            fill="#ffffff"
            transform="skewY(-10deg)"
            style={{ transformOrigin: "bottom" }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 0.3, height: 140 }}
            transition={{
              duration: 1.5,
              delay: 0.5,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
          <motion.rect
            x="70"
            y="20"
            width="20"
            height="180"
            fill="#ffffff"
            transform="skewY(-10deg)"
            style={{ transformOrigin: "bottom" }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 0.3, height: 180 }}
            transition={{
              duration: 1.5,
              delay: 1,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
        </svg>

        {/* Floating Sphere (symbolic of global education and finance) */}
        <svg
          className="absolute top-1/3 left-[35rem] transform -translate-x-1/2 md:left-[40rem]"
          width="80"
          height="80"
        >
          <motion.circle
            cx="40"
            cy="40"
            r="30"
            fill="transparent"
            stroke="#ffffff"
            strokeWidth="3"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 0.5, scale: 1 }}
            transition={{
              duration: 2,
              delay: 0.5,
              repeat: Infinity,
              repeatType: "mirror",
            }}
          />
        </svg>
      </div>

      {/* Content */}
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between relative z-1">
        <motion.div
          className="md:w-1/2  md:mb-0 px-4" // Added padding for small screens
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl md:text-4xl font-bold min-[320px]:mb-3 md:mb-5">About Me</h2> {/* Responsive font size */}
          <p className="min-[320px]:mb-3 md:mb-5 text-sm md:text-base"> {/* Responsive text size */}
            Hi, I'm Maneish, a passionate stock market enthusiast with a strong commitment to empowering fellow investors. My journey in the stock market has equipped me with valuable insights and strategies that I love to share with others.
          </p>
        </motion.div>

        {/* <motion.div
          className="md:w-1/3 px-4" // Added padding for small screens
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        > */}
        {/* <img src="/assets/manish_2.png" alt="About Me" className="rounded-lg shadow-lg min-[320px]:max-w-[12rem] h-auto md:w-[20rem]" /> Ensured image is responsive */}
        {/* </motion.div> */}
      </div>
    </section>
  );
};

export default About;
