// import React from "react";
// import { motion } from "framer-motion";

// const Testimonial = () => {
//   const data = [
//     {
//       name: "Navin",
//       review:
//         "It will not be an exaggeration at all if I call Maneish as a  magician of chart reading and analysis in the stock market. The way he breaks , turns, twists,drop and drags  the different element of charts and associated tools &indicators  to get the accurate prediction of expected behaviour of market many a times shuffling , superimposing between different timeframes  like Maradona  dribbling past the array of rival team's defenders in a football ground and putting the ball into the GOAL that every one is expecting from the stock market . He makes you fall in love with Stock market charts 😊 What makes Maneish really impressive and different from others is his clarity of thoughts , vast knowledge not just about the key central aspects of stock market but also a complete mastery about concepts on the periphery like market economics, role played by  psychology of all involved players combining it with astrological  & numerological angle  and presentation of these in such a greatly simplified and easily understandable manner that even a novice like me feels like an expert ! I recommend Maneish to every one who  really wants to learn nuances of stock market and  make a long ,profitable association with it .",
//     },
//     {
//       name: "Michael Smith",
//       review:
//         "Absolutely loved the experience. The staff was friendly and professional, and I couldn't be happier with the results.",
//     },
//     {
//       name: "Emily Davis",
//       review:
//         "I had a great time and learned a lot. The course exceeded my expectations and was well worth the investment.",
//     },
//     {
//       name: "David Brown",
//       review:
//         "Fantastic service! The team went above and beyond to ensure I was satisfied with everything.",
//     },
//     {
//       name: "Sarah Wilson",
//       review:
//         "Highly recommend! The process was smooth, and I appreciated the attention to detail.",
//     },
//     {
//       name: "James Taylor",
//       review:
//         "A truly remarkable experience. The staff was knowledgeable and made me feel welcome from start to finish.",
//     },
//     {
//       name: "Jessica Moore",
//       review:
//         "I was impressed with the professionalism and quality of service. Definitely worth every penny!",
//     },
//     {
//       name: "Daniel Anderson",
//       review:
//         "Excellent experience overall! The quality of the work was outstanding, and I will be returning in the future.",
//     },
//     {
//       name: "Laura Thomas",
//       review:
//         "An amazing team! They are dedicated to making sure you get the best possible outcome.",
//     },
//     {
//       name: "Matthew Jackson",
//       review:
//         "I can't recommend this enough! They exceeded my expectations and delivered fantastic results.",
//     },
//     {
//       name: "Sophie White",
//       review:
//         "I had a rather long journey before discovering this service, and I can confidently say it was worth the wait. From the very first interaction, the staff made me feel valued. The personalized approach and attention to detail transformed my experience. I now feel more confident and empowered. Thank you for going the extra mile!",
//     },
//     {
//       name: "Ben Martin",
//       review:
//         "Great service! Very professional and effective. I’m happy with the outcome!",
//     },
//     {
//       name: "Claire Lee",
//       review:
//         "The team was very supportive and attentive to my needs. They explained every step of the process, making sure I understood everything. The results have been incredible, and I’m grateful for the care they provided.",
//     },
//     {
//       name: "Tom Harris",
//       review: "Top-notch experience!",
//     },
//   ];

//   return (
//     <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 mt-8">
//       {data.map((testimonial, index) => (
//         <motion.div
//           key={index}
//           className="p-4 bg-white rounded-lg shadow-lg border-l-4 border-blue-500 hover:shadow-xl transition-shadow duration-300"
//           initial={{ opacity: 0, y: 20 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           exit={{ opacity: 0, y: -20 }}
//           transition={{ duration: 0.4, ease: "easeInOut", delay: index * 0.1 }}
//           viewport={{ once: true, amount: 0.2 }}
//         >
//           <div className="flex items-center mb-3">
//             <div className="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center text-lg font-semibold">
//               {testimonial.name.charAt(0)}
//             </div>
//             <div className="ml-3">
//               <p className="text-lg font-semibold text-gray-800">
//                 {testimonial.name}
//               </p>
//             </div>
//           </div>
//           <p className="text-gray-600 text-sm h-[150px] overflow-y-auto">{testimonial.review}</p>
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// export default Testimonial;


import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import Modal from "react-modal";
import testimonials from "../../data/student_reviews.json";


Modal.setAppElement("#root");

const Testimonial = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReview, setCurrentReview] = useState("");

  const openModal = (review) => {
    setCurrentReview(review);
    setModalIsOpen(true);
    // document.querySelector("#root").setAttribute("inert", "true");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentReview("");
  };

  return (
    <div className="testimonial-carousel-container py-8">
      <h2 className="text-center font-extrabold text-3xl sm:text-4xl md:text-5xl my-4">Testimonials</h2>
      <Swiper
        spaceBetween={30}
        navigation
        modules={[Navigation]}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        loop={true}
      >
        {testimonials.sort((a, b) => b.review.length - a.review.length).map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="testimonial-card border p-4 rounded-lg shadow-md bg-white">
              <div className="flex items-center mb-4 gap-x-2">
                <div className="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center text-lg font-semibold">
                  {testimonial.name.charAt(0)}
                </div>
                <div>
                  <h3 className="font-bold">{testimonial.name}</h3>
                  <p className="text-sm text-gray-600">{testimonial.designation}</p>
                </div>
              </div>
              <p className="text-yellow-500 mb-2">
                {"★".repeat(testimonial.rating)}
              </p>
              <p className="text-gray-700 mb-2">
                {testimonial.review.slice(0, 50)}...
                <button
                  onClick={() => openModal(testimonial)}
                  className="text-blue-500 underline ml-1"
                >
                  Read more
                </button>
              </p>
              <p className="text-sm font-bold text-gray-600">{testimonial.company}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal */}
      {modalIsOpen && (
        <>
          <div className="modal-backdrop fade show" onClick={closeModal}></div>
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-lg shadow-lg border-0">
                <div className="modal-body p-6 ">
                  <div className="text-center my-2">
                    <h5 className="modal-title text-xl font-semibold">
                      <div className="flex items-center mb-4 gap-x-2">
                        <div className="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center text-lg font-semibold">
                          {currentReview.name.charAt(0)}
                        </div>
                        <div>
                          <h3 className="font-bold">{currentReview.name}</h3>
                          <p className="text-sm text-gray-600">{currentReview.designation}</p>
                        </div>
                      </div>
                    </h5>
                  </div>
                  <p className="text-yellow-500 mb-2">
                    {"★".repeat(currentReview.rating)}
                  </p>
                  <p className="text-gray-700">
                    {currentReview.review}
                  </p>
                  <div className="flex justify-center my-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Testimonial;

