import React from "react";
import { motion } from "framer-motion";
import "./Feedback.css";

const Feedback = () => {
  const CardData = ({ data }) => {
    const handleMouseEnter = (e) => {
      const video = e.target;
      // video.muted = true; // Mute the video to allow autoplay
      video.play(); // Play the video on hover
    };

    const handleMouseLeave = (e) => {
      const video = e.target;
      video.pause(); // Pause the video when not hovering
      // video.currentTime = 0; // Reset to start
    };


    return (
      <div className="p-2 flex flex-col items-center">
        <div className="font-extrabold text-white">COURSE</div>
        <div className="h-[2px] w-[90%] bg-red-600 rounded-lg"></div>
        <div className="text-[0.5rem] text-white tracking-[5px]">REVIEW</div>
        <div className="mt-2">
          <video className="w-full h-[250px] rounded-lg" controls onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <source src={`/assets/feedback${data}.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-white mt-2">THE CHARTISTT</div>
      </div>
    );
  };

  const VideoCard = ({ data, index }) => {
    // Animation variants
    const cardVariants = {
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, delay: index * 0.1 },
      },
    };

    return (
      <div className="col px-3">
        <motion.div
          className="w-full h-[350px] bgImage rounded-xl shadow-lg"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={cardVariants}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <CardData data={data} />
        </motion.div>
      </div>
    );
  };

  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-4">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele, idx) => (
        <VideoCard key={idx} data={ele} index={idx} />
      ))}
    </div>
  );
};

export default Feedback;
