import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';


const AppDownload = ({ modalIsOpen, closeModal }) => {

    if (modalIsOpen) return (
        <>
            <div className="modal-backdrop fade show" onClick={closeModal}></div>
            <div
                className="modal fade show d-block"
                tabIndex="-1"
                role="dialog"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content rounded-lg shadow-lg border-0">
                        <div className="modal-body p-6 ">
                            <div className="text-center my-2">
                                <h5 className="modal-title text-xl font-semibold">
                                    <div className="flex items-center mb-4 gap-x-2 justify-center">
                                        <div className="flex items-center">
                                            <h3 className="font-bold text-black">Learning with Us</h3>
                                            <FaInfoCircle className="ml-2 text-blue-500" />
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className="my-4 text-black">
                                <ol className="list-decimal list-inside text-left">
                                    <li>Tap OK to download the MyInstitute App.</li>
                                    <li>Enter ORG Code <span className="font-semibold">GMHMW</span> to access your course materials.</li>
                                    <li>Dive into a world of knowledge—Happy Learning!</li>
                                </ol>
                            </div>
                            <div className="flex justify-center my-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mr-2"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => window.location.href = 'https://apps.apple.com/in/app/myinstitute/id1472483563'} // Redirect to your website
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default AppDownload